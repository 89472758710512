html, body {
  background: #111 url('bg.jpg') center center;
  background-size: cover;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  min-height: 100vh;
}

form {
  line-height: 1.4;
  padding: 30px;
  max-width: 400px;
  width: 100%;
  color: #fff;
  flex: 1;
}

label {
  display: block;
}

.form-group {
  margin-top: 20px;
}

select {
  background: white;
  padding: 5px;
  font-size: 9pt;
  margin-top: 12px;
}

input#test {
  width: auto
}

form input, form textarea {
  width: 100%;
  background: black;
  border: 1px solid #333537;
  padding: 12px 7px;
  margin: 7px 0;
  color: #fff;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 12px 7px;
  width: 100%;
  border: none;
  font-size: 12pt;
  font-weight: 600;
  background: #2345eb;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  transition: all .15s ease-in-out;
}

button:hover {
  background: #0b29be;
  transform: translateY(-5px);
}
